
<template>
 <vx-card>
  <div class="vx-row">
    <div class="vx-col md:w-full mt-4">
      <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;"> Les champs suivis du signe (*) sont obligatoires pour l'enregistrement du prescripteur.</span>
    </div>
    <div class="vx-col md:w-1/2 w-full mt-5">
      <vs-input label="Nom & Prénoms *" v-model="NomPresc" class="w-full" />
    </div>
    <div class="vx-col md:w-1/2 w-full mt-5">
      <vs-select autocomplete label="Centre hospitalier *" class="w-full" v-model="IdCenterHospi" >
        <vs-select-item :key="index" :value="item._id" :text="item.RaisonSoc" v-for=" (item, index ) in centreHospitaliers" />
      </vs-select>
    </div>
    <div class="vx-col md:w-1/2 w-full mt-5">
      <vs-input label="Téléphone" v-model="TelPresc" class="w-full" />
    </div>
    <div class="vx-col md:w-1/2 w-full mt-5">
      <vs-input type="email" label="Email" v-model="EmailPresc" class="w-full" />
    </div>
  </div>
  <div class="vx-row">
    <div class="vx-col md:w-1/2 w-full mt-5">
      <vs-input label="Remarque" v-model="Remarque" class="w-full" />
    </div>
    <div class="vx-col md:w-1/2 w-full md:mt-8">
      <div class="demo-alignment">
        <span>Ristourne:</span>
        <div class="flex">
          <vs-checkbox v-model="Ristourne"></vs-checkbox>
          <vs-input-number class="ml-8" v-model="taux" label="taux:"/>
        </div>
      </div>
    </div>
  </div>
  <div class="vx-row mt-base">
    <div class="vx-col text-right w-full ml-auto">
      <vs-button color="warning" type="border" class="mr-3 mb-2" @click="goToPrescripteurListe">Annuler</vs-button>
      <vs-button class="mb-2" :disabled="!validateForm" @click="updatePrescripteur" >Enregistrer</vs-button>
    </div>
  </div>
 </vx-card>

</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent
  },
  data () {
    return {
      IdCenterHospi: null,
      NomPresc: null,
      PrenomPresc: null,
      Ristourne: false,
      taux: 0,
      TelPresc: null,
      Mobile: null,
      FaxPresc: null,
      AdrPresc: null,
      EmailPresc: null,
      Remarque: null
    }
  },
  computed: {
    centreHospitaliers () {
      return this.$store.state.centre_hospitalier.centre_hospitaliers
    },
    validateForm () {
      return !this.errors.any() && this.NomPresc !== null && this.IdCenterHospi !== null
    }
  },
  methods: {
    goToPrescripteurListe () {
      this.$router.push('/apps/prescripteur/prescripteur-list').catch((err) => { console.log(err) })
    },
    updatePrescripteur () {

      const payload = {
        IdCenterHospi:this.IdCenterHospi,
        NomPresc: this.NomPresc,
        PrenomPresc: this.PrenomPresc,
        taux: this.taux,
        Ristourne: this.Ristourne,
        TelPresc: this.TelPresc,
        Mobile: this.Mobile,
        FaxPresc: this.FaxPresc,
        AdrPresc: this.AdrPresc,
        EmailPresc: this.EmailPresc,
        Remarque: this.Remarque,
        id: this.$route.params.prescripteurId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('prescripteur/updatePrescripteur', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.$router.push('/apps/prescripteur/prescripteur-list').catch((err) => { console.log(err) })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPrescripteurById () {
      this.$store.dispatch('prescripteur/getPrescripteurById', this.$route.params.prescripteurId)
        .then((resp) => {
          this.IdCenterHospi = resp.data.IdCenterHospi ? resp.data.IdCenterHospi : null
          this.NomPresc = resp.data.NomPresc ? resp.data.NomPresc : null
          this.PrenomPresc = resp.data.PrenomPresc ? resp.data.PrenomPresc : null
          this.taux = resp.data.taux ? resp.data.taux : null
          this.Remarque = resp.data.Remarque ? resp.data.Remarque : null
          this.TelPresc = resp.data.Adresse.TelPresc ? resp.data.Adresse.TelPresc : null
          this.Mobile = resp.data.Adresse.Mobile ? resp.data.Adresse.Mobile : null
          this.FaxPresc = resp.data.Adresse.FaxPresc ? resp.data.Adresse.FaxPresc : null
          this.AdrPresc = resp.data.Adresse.AdrPresc ? resp.data.Adresse.AdrPresc : null
          this.EmailPresc = resp.data.Adresse.EmailPresc ? resp.data.Adresse.EmailPresc : null
        })
        .catch((err) => { console.log(err) })
    },
    getCentreHospitalier () {
      this.$store.dispatch('centre_hospitalier/getCentreHospitalier')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getPrescripteurById()
    this.getCentreHospitalier()
  }
}
</script>
